@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100,0,300,0,400,0,500,0,600,0,700;1,300&display=swap');


:root {
    --red: rgb(230, 46, 4);
    --light-red: rgb(255, 240, 235);
    --orange: rgb(255, 158, 39);

    --light-gray: rgb(238, 238, 238);
    --dark-gray: rgb(40, 40, 40) ;
    --white: rgb(255, 255, 255);
    --border-radius: 5px;
    --main-transition: .3s;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* --------------------------------NAV BAR STARTS-------------------------------- */
nav{
    width: 100%;
    height: 60px;
    margin: auto;
    padding: .2em .2em;
    background-color: var(--light-gray);

    position: fixed;
    top: 0;
    z-index: 2;



    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    gap: .5em;
}
@media (min-width:768px) {
    nav{
        padding: .2em 1em;
    }
}

.simplishop_logo{
    height: 100%;
    max-height: 30px;

}
.simplishop_logo .half_logo{
    display:none;
}
@media (max-width:768px){
    .simplishop_logo .full_logo {
        display: none;
    }
    .simplishop_logo .half_logo{
        display: inline;
    }
}

.simplishop_logo img{
    height: 100%;
}

.search_bar_wrapper{
    width: 80%;
    min-width: 150px;
    height: 40px;
    border: 2px solid var(--dark-gray);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search_bar_wrapper input{
    width: calc(100% - 40px);
    height: 100%;
    padding:  0 1em;
    font-size: 1rem;
    font-weight: 500;
    border: none;
}
@media (max-width:768px) {
   .search_bar_wrapper input{
    padding: 0 .5em;
}
}

.search_bar_wrapper input:focus{
    outline: none;
}

.search_bar_wrapper .search_icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
    background-color: var(--dark-gray);
    color: var(--white);
    cursor: pointer;
}

.cart_and_user_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: .5em;
}

.cart_and_user_wrapper .cart_wrapper{
    cursor: pointer;
    position: relative;
}
.cart_item_count {
    font-size: .8rem;
    position: absolute;
    top: -.8em;
    right: -.5em;

    background-color: var(--dark-gray);
    color: #eee;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
}

.user_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.user_wrapper>.drop_down_parent{
    display: flex;
    align-items: flex-end;
    gap: .2em;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;

    position: relative;
}
@media (max-width:576px){
    .user_wrapper>.drop_down_parent{
        font-size: 0.5rem;
    }
}

.user_wrapper .drop_down_user{
    position: absolute;
    top: 105%;
    right: 0;

    font-size: .9rem;
    font-weight: 500;
    max-height: 0px;
    min-width: 150px;
    background-color: var(--dark-gray);
    overflow: hidden;
    list-style: none;
    transition: max-height var(--main-transition) linear;
}
.drop_down_parent:hover .drop_down_user{
    max-height: 400px;
}

.drop_down_user li{
    padding: 1em .5em;
    color: #ccc;
}

.drop_down_user li.register{
    cursor: pointer;
    transition: var(--main-transition);
    border-top: 1px solid #666;
}
.drop_down_user li.register:hover{
    background-color: var(--red);
    color: var(--white);
}

/* CART SIDE BAR */
.cart_side_bar {
    width: 0%;
    max-width: 90%;
    height: 99vh;
    background-color: rgb(51, 51, 51) ;
    box-shadow: 0 0 10px .3px rgba(0, 0, 0, 0.5);

    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;
    overflow: hidden;

    transition: .3s;

}
@media (min-width: 768px) {
    .cart_side_bar {
        max-width: 500px;
    }
}

.cart_side_bar_header_section {
    padding: 1em;
    color: #ccc;
    border-bottom: thin solid #aaa;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close_cart_sidebar {
    font-size: 1.5rem;
    border-bottom: 2px solid white;
}

.close_cart_sidebar:hover {
    color: var(--red);
    border-bottom: 2px solid var(--red);
}

.cart_side_bar_cart_items_sections {
    height: calc(100% - 230px);
    padding-top: 1em;
    overflow: scroll;
}

.cart_side_bar_cart_items_sections::-webkit-scrollbar {
    display: none;
}

.ssbci {
    height: 100px;
    color: #ccc;
    padding: .5em 0;
    margin: 0 1em;
    border-bottom: thin solid #aaa;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .2em;
}

.ssbci_item_img_desc {
    height: 100%;
    margin-left: .5em;
    color:#eee !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}

.ssbci_img {
    height: 85%;
    padding: .2em;
    background-color: #ccc;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ssbci_img img {
    height: 100%;
}

.ssbci_qty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.ssbci_qty .qtyInput {
    width: 40px;
    height: 30px;
    text-align: center;
    border: none;
    border-radius: var(--border-radius);
    padding: 0 .2em;
}

.ssbci_qty input:focus {
    outline: 2px solid #666;
}

.ssbci_qty input::-webkit-outer-spin-button,
.ssbci_qty input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.ssbci_qty input[type=number] {
    -moz-appearance: textfield;
}


.ssbci_remove_item {
    width: 22px;
    height: 22px;
    border: 2px solid gray;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.ssbci_remove_item:hover {
    border: 2px solid var(--red);
    color: var(--red);
}

.cart_side_bar_bottom_element {
    position: absolute;
    bottom: 0;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.05rem;
    font-weight: bold;
}

.cart_side_bar_bottom_element .subtotals {
    height: 50px;
    width: 100%;
    background: var(--white);
    padding: 0 1em;
    border-top: thin solid #ccc;
    border-bottom: thin solid #ccc;
    margin-bottom: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.cart_side_bar_bottom_element button {
    width: 100%;
    max-width: 450px;
    height: 50px;
    border: none;
    font-weight: bold;
    color: var(--white);
    border: 1px solid #666;
    background-color: inherit;
    margin-bottom: .5em;
    cursor: pointer;

}

@media (max-width:768px) {
    .cart_side_bar_bottom_element button {
        height: 35px;
    }

    .cart_side_bar_bottom_element .subtotals {
        height: 50px;
    }
}

/* --------------------------------NAV BAR ENDS-------------------------------- */



/* --------------------------------LANDING PAGE STARTS-------------------------------- */
/* banner */
.hero_section {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 0 0 2em;
    background-color: rgb(238, 238, 238);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

@media (max-width: 768px) {
    .hero_section {
        flex-direction: column;
        gap: .5em;
    }
}

.hero_section .hero_img {
    width: 40%;
}

@media (max-width:768px) {
    .hero_section .hero_img {
        width: 100%;
    }
}

.hero_section .hero_img img {
    width: 100%;
    max-width: 700px;
}

.hero_section .hero_text {
    width: 40%;
    font-size: 1.1rem;
    color: #333;
    letter-spacing: .05rem;
    text-align: center;
}

@media (max-width:768px) {
    .hero_section .hero_text {
        width: 100%;
    }
}


.hero_text .banner_title {
    font-size: 3rem;
    margin-bottom: .5em;

}

.banner_desc {
    margin-top: 1em;
    margin-bottom: 2em;
}

@media (max-width:768px) {
    .hero_text .banner_title {
        font-size: 1.8rem;
    }

    .banner_desc {
        display: none;
    }
}

.hero_text .banner_btn {
    width: 150px;
    height: 45px;
    text-decoration: none !important;
    color: var(--white);
    background-color: rgb(40, 40, 40);
    font-size: 1.2rem;
    padding: .5em;
    border: none;
    cursor: pointer;
}

@media (min-width:768px) {
    .banner_btn {
        float: left;
    }
}

/* --------------------------------LANDING PAGE ENDS-------------------------------- */


/* --------------------------------PRODUCTS CATALOGUE STARTS-------------------------------- */
.products_page{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: .5em;
    padding: 0 .2em;
    margin-bottom: 4em;
    
}
.products_page .filter_container{
    width: 100%;
    background-color: var(--dark-gray);
    color: #eee;
    padding: 2em .5em 1em;
}
@media (max-width:768px) {
    .products_page {
            flex-direction: column;
        }
    .products_page .filter_container {
        border-bottom: 2px solid #333;
    }
}
@media (min-width:768px){

    .products_page .filter_container{
        width: 50%;
        max-width: 300px;
        min-width: 200px;
        height: calc(100vh - 60px);
        padding: 2em 1em 1em;

        position: sticky;
        top: 60px;
    }

    .products_page .filter_container{
     border-right: 2px solid #333;       
    }
}

.filter_container .filter_header{
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1.5em;
}

.filter_container .filter_form{
    /* border: 2px solid magenta; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2em;

    margin-left: .5em;
}

.filter_form .price{
    display: flex;
    gap: 1em;
}
.filter_form .price input{
    width: 50px;
    height: 30px;
    text-align: center;
}

.filter_form .clear_filter_btn{
    width: 100%;
    height: 40px;
    color: #eee;
    border: none;
    background-color: #666;
    margin: 2em auto;
    transition: var(--main-transition);
    cursor: pointer;
}
.filter_form .clear_filter_btn:hover{
    border: 1px solid var(--light-gray);
    background: inherit;
}


.products_page .products_section{
    width: 100%;
}


.catalogue_header{
    margin-bottom: 1em;
        padding: .5em 3em;
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.catalogue_header h1 {
    font-size: 3rem;
    font-weight: 500;
    color: #666;
}
@media (max-width:576px) {
    .catalogue_header h1 {
        font-size: 1.5rem;
        padding: .5em 1em;
    }
}

.catalogue_header .drop_down_category {
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    padding: 0 .5em .5em;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.drop_down_category ul {
    list-style: none;
    display: block;
    max-height: 0;

    position: absolute;
    top: 105%;
    right: 0;
    z-index: 3;
    overflow: hidden;

    width: 150%;
    background-color: var(--dark-gray);
    color: #ddd;
    transition: max-height var(--main-transition) linear;
}

.drop_down_category ul li {
    padding: .5em .8em .5em;
    cursor: pointer;
}

.drop_down_category ul li:hover {
    background-color: #eee;
    color: #333;
}

.drop_down_category:hover ul {
    max-height: 500px;
}


.products_section .products_catalogue{
    min-height: 80vh;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
   grid-column-gap: 1.5rem;
   grid-row-gap: 2rem;
}
@media (max-width:578px) {
    .products_section .products_catalogue {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
}

.over_lay {
    position: absolute;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    transition: var(--main-transition);
    border-radius: 5px;
    height: 0%;
    width: 100%;
    background-color: rgba(238, 238, 238, 0.7);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.single_product:hover .over_lay {
    height: 100%;
}

.over_lay .view,
.over_lay .to_cart {
    font-size: 1rem;
    text-align: center;
    width: 90%;
    background-color: var(--dark-gray);
    color: #eee;
    text-decoration: none;
    padding: 1em 2em;
    cursor: pointer;
}

.over_lay .view:hover,
.over_lay .to_cart:hover {
    background-color: rgba(255, 165, 0, 0.8);
}
.over_lay .to_cart{
    border: none;
}

.single_product {
    box-shadow: 0 0 10px .5px rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius);
    padding: .5em;
    font-weight: 500;
    position: relative;
    /* max-height: 335px; */
}

.single_product .flex_image {
    width: 100%;
    max-height: 60%;
    margin-bottom: .5em;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.single_product .flex_image img {
    width: 100%;
}

.nrp {
    height: calc(40% - .5em);
    background-color: rgb(238, 238, 238);
    color: #222;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.single_product .product_name {
    font-weight: bold;
    text-align: center;
    margin-top: 1em;

    width: 100%;
    height: 25px;
    text-align: center;
    overflow: hidden;
}

.product_price,
.ratings {
    text-align: center;
}

/* --------------------------------PRODUCTS CATALOGUE ENDS-------------------------------- */


/* --------------------------------SIGNUP  STARTS-------------------------------- */
.form{
   position: fixed;
   top: 0px;
   z-index: 5;
   background-color: rgba(0, 0, 0, 0.5);
   width: 100%;
   height:  calc(100vh - 60px);
   padding: .5em .5em;
   border: 2px solid magenta;

   display: flex;
   justify-content: center;
   align-items: center;
}
.form_container{
    width: 100%;
    min-height: 300px;
    margin: auto;
    background: #fff;

    position: relative;
}
@media (min-width:992px){
    .form_container{
        max-width: 700px;
    }
}
.reg_log_header{
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #333;
    padding: 1em .5em;
}
.reg_log_header>span{
    cursor: pointer;
}
.reg_log_header>span:hover{
    color: var(--red);
}

.form_container .sign_form{
    padding: 1em .5em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5em;
}
.sign_form input{
    width: 100%;
    max-width: 600px;
    height: 40px;
    padding: 0 1em;
}
.sign_form button{
    width: 100%;
    max-width: 300px;
    height: 40px;
}
.form_container .btn_close{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--red);
    cursor: pointer;

    position: absolute;
    top: 10px;
    right: 10px;
}

/* --------------------------------SIGNUP ENDS-------------------------------- */


/* --------------------------------SINGLE PRODUCT PAGE STARTS-------------------------------- */
.single_product_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 3em;

    margin-bottom: 2em;
}
@media (max-width:992px){
    .single_product_container{
        flex-direction: column;
    }
}

.product_image_container{
    /* border: 2px solid yellow; */
    width: 40%;
    min-height: 100px;
}
.product_descr-container{
    /* border: 2px solid red; */
    width: 40%;
    min-height: 100px;
    padding: .5em ;
}
@media (max-width:992px) {
    .product_image_container, .product_descr-container{
        width: 100%;
    }
    .product_descr-container{
        max-width: 500px;
        margin: auto;
    }
}
@media (min-width:992px) {

    .product_descr-container{
        max-width: 400px;
    }
}
.product_image_container .full_image_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 992px){
    .product_image_container {
        display: flex;
        gap: .2em;
    }
    .full_image_wrapper{
        width: calc(100% - 60px);
    }
    .small_image_wrapper{
        width: 60px;
    }
}
.full_image_wrapper img{
    width: 100%;
    max-width: 500px;
}
@media (max-width:992px){
    .full_image_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2em;
    }

    .full_image_wrapper img{
        max-width: 300px;
    }
}

.small_image_wrapper img{
    width: 100%;
    margin-bottom: .7em;
}
@media (max-width: 992px) {
    .small_image_wrapper{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1em;
        padding: 0 1em;
    }
    .small_image_wrapper img {
        width: 23%;
        max-width: 60px;
    }
}

.product_descr-container {
    display: flex;
    flex-direction: column;
    gap: 2em;

}
.product_descr-container .product_name{
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: .5em;
}

.product_descr-container .product_descr{
    font-size: .8rem;
    text-align: justify;
    letter-spacing: .03rem;
    word-spacing: .2em;
    line-height: 1.5em;
    margin-top: 2em;
}

.product_descr-container .spbtn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2em;
}
.spbtn button{
    font-size: .8rem;
    font-weight: 700;
    width: 50%;
    height: 40px;
    max-width: 200px;
    cursor: pointer;
    border: none;
}

.product_descr-container .spqty_wrapper{
    border: 2px solid  rgba(0, 0, 0, 0.04);
    text-align: center;
    padding: .2em;
    margin-bottom: .3em;
}
.spqty_wrapper span{
    display: inline-block;
}
.spqty_wrapper .qty_modifier{
    width: 25px;
    height: 25px;
    font-size: 1.1rem;
    font-weight: 500 ;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.spqty_wrapper .spqty{
    font-size: 1.3rem;
    margin: 0 .8em;
}

/* product_meta */
.product_meta{
    border: 2px solid rgba(0, 0, 0, 0.2);
    padding: 2em;
    margin-bottom: 1em;
}

.related_product{
    margin: 2em 0;

}
.related_product .related_prod_header{
    margin: 1em 1em;
    font-weight: 400;
}

.related_product_grid{
    border: none !important;
    width: 100%;
    max-width: 1200px;margin: auto;
    padding: 1em .5em;
}
/* --------------------------------SINGLE PRODUCT PAGE ENDS-------------------------------- */


/* --------------------------------CHECKOUT STARTS-------------------------------- */

.checkout_container{
    background: var(--light-gray);
    min-height: calc(200vh - 60px);
    padding: 2em .5em;
}
.checkout{
    width: 100%;
    max-width: 1100px;
    margin: auto;
    color: #333;
    font-weight: 500;
}
@media (min-width:768px){
    .checkout{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}
.checkout_header{
    font-size: 1.5rem;
    color: #333;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto 1em;
    font-weight: 500;
    padding-bottom: 1em;
    border-bottom: 1px solid #ccc;
}

.checkout_form_container{
    max-width: 600px;
    margin: auto;
    min-height: 300px;
}
@media (min-width:768px) {
    .checkout_form_container{
        width: 50%;
    }
}
.checkout_form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2em;
}
.checkout_form label{
    width: 100%;
}
.checkout_form input{
    display: block;
    width: 100%;
    height: 40px;
    border: 1px solid #aaa;
    outline: none;
    margin-top: .5em;
}

.order_summary_wrapper{
    min-height: 300px;
    max-width: 480px;
    padding: 2em 1em ;
    float: right;
    border: 1px solid #ccc;
}

.order_summary_header{
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1.5em;
}
.order_summary_wrapper .group{
    font-size:1rem;
    font-weight: 400;
    margin-bottom: 1em;
}
.group>span{
    float: right;
}
.order_summary_wrapper .divider{
    border: 2px solid #aaa;
}
.order_summary_wrapper .total{
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1em;
}
.total>span{
    float: right;
}
.place_order_btn{
    font-size: 1.2rem;
    font-weight: 400;
    background-color: var(--dark-gray);
    color: #ddd;
    border: none;
    width: 100%;
    /* max-width: 300px; */
    height: 40px;
    margin: 2em auto .5em;
    cursor: pointer;
}
.order_policy_text{
    font-size: .8rem;
    text-align: center;
}

/* --------------------------------CHECKOUT ENDS-------------------------------- */


/* --------------------------------FOOTER STARTS-------------------------------- */
footer {
    font-size: 1rem;
    text-align: center;
    padding: 1.5em .5em;
    color: #aaa;
    background: var(--dark-gray);
}
/* --------------------------------FOOTER ENDS-------------------------------- */